import React, { useState, useRef, useEffect } from 'react'
import { MetaSeo } from 'components'
import Head from 'next/head'
import { useContext } from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CartContext } from 'utils/context'
import { WContainer, BoxQuote, ProdottiBundle, JsonLd } from 'components/atoms'
import {
  Breadcrumbs,
  BeautyRoutine,
  BoxMarketing,
  BoxMarketing2,
  PrincipiAttivi,
  ProdottoCard,
} from 'components/molecules'
import { ProdottoInfo, ProdottoStickyBar } from 'components/organisms'
import { LABEL_CORRELATI, LIST_CORRELATI } from 'utils'
import { useIsClient } from 'utils/hooks'
import { trackingGAevent, trackingProductImpression } from 'utils/gtm'

SwiperCore.use([Pagination])

function ProdottoTemplate({ prodotto, pagina, correlati = [] }) {
  const { addToCart } = useContext(CartContext)
  const stickyRef = useRef(null)
  const [stickyVisible, setStickyVisible] = useState(false)
  const isClientSide = useIsClient(false)

  const boxQuote = prodotto.boxQuote.length ? prodotto.boxQuote[0] : null
  const primoBoxMarketing = prodotto.boxMarketing.length ? prodotto.boxMarketing[0] : null

  const logicaCorrelati = prodotto.logicaCorrelati.toLowerCase()

  useEffect(() => {
    if (correlati.length) trackingProductImpression(correlati, LIST_CORRELATI[logicaCorrelati])
  }, [correlati])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    setStickyVisible(window.scrollY > stickyRef?.current?.offsetTop - 200)
  }

  return (
    <>
      {isClientSide && (
        <ProdottoStickyBar prodotto={prodotto} addToCart={addToCart} visible={stickyVisible} />
      )}
      <main className="page prodotto-detail">
        {prodotto.immagini.length ? (
          <Head>
            <link rel="preload" href={prodotto.immagini[0].imageThumb} as="image" />
          </Head>
        ) : null}
        <MetaSeo seo={pagina.seo} />
        <JsonLd item={prodotto} />
        <WContainer>
          <Breadcrumbs breadcrumbs={pagina.breadcrumbs} classes="prodotto-detail__breadcrumbs" />
          <ProdottoInfo prodotto={prodotto} addToCart={addToCart} />
          {prodotto.bundle && prodotto.prodottiBundle.length ? (
            <ProdottiBundle prodotti={prodotto.prodottiBundle} isBundle={prodotto.bundle} />
          ) : prodotto.kit && !prodotto.bundle && prodotto.prodottiKit.length ? (
            <ProdottiBundle prodotti={prodotto.prodottiKit} isBundle={false} />
          ) : null}
        </WContainer>
        <div ref={stickyRef}></div>
        {primoBoxMarketing || boxQuote ? (
          <section className="prodotto-detail__marketing">
            {primoBoxMarketing && <BoxMarketing box={primoBoxMarketing} />}
            {boxQuote && <BoxQuote box={boxQuote} />}
          </section>
        ) : null}
        {prodotto.principiAttivi.length ? (
          <PrincipiAttivi
            attivi={prodotto.principiAttivi}
            complementari={prodotto.principiComplementari}
            prodotto={prodotto}
          />
        ) : null}
        {prodotto.rituale.length ? (
          <BeautyRoutine steps={prodotto.rituale} />
        ) : prodotto.consigli.length ? (
          <BeautyRoutine steps={prodotto.consigli} rituale={false} />
        ) : null}
        {correlati.length ? (
          <section className="prodotto-detail__correlati">
            <WContainer>
              <h3 className="prodotto-detail__correlati__title">
                {LABEL_CORRELATI[logicaCorrelati]}
              </h3>
              <div className="swiper--with-pagination">
                <Swiper
                  spaceBetween={16}
                  slidesPerView={2}
                  watchOverflow
                  pagination={{ clickable: true }}
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {correlati.map((p, index) => (
                    <SwiperSlide key={p.pk}>
                      <ProdottoCard
                        prodotto={p}
                        waPosition={index + 1}
                        waList={LIST_CORRELATI[logicaCorrelati]}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </WContainer>
          </section>
        ) : null}
        {prodotto.linea && (
          <BoxMarketing2
            box={{
              titolo: prodotto.linea.nome,
              testo: prodotto.linea.lancio,
              finalUrl: prodotto.linea.url,
              imageThumb: prodotto.linea.lineaLaunchThumb,
              contentStyle: 'highlight',
            }}
            onClick={() =>
              trackingGAevent(
                44,
                'product',
                'scopri_la_linea',
                prodotto.linea.nome,
                null,
                prodotto.codice
              )
            }
          />
        )}
      </main>
    </>
  )
}

export default ProdottoTemplate
