import PlaceholderContent from 'components/atoms/PlaceholderContent/PlaceholderContent'
import React, { useEffect, useState } from 'react'
import { API_HOST } from 'utils'

const Placeholder = React.memo(({ placeholder, titoloArticolo }) => {
  const [content, setContent] = useState(null)

  const parseContent = async () => {
    let content = null

    if (placeholder.url) {
      content = (
        <iframe className={'pagebuilder-placeholder'} src={`${API_HOST}${placeholder.url}`} />
      )
    } else {
      content = <PlaceholderContent content={placeholder.content} titoloArticolo={titoloArticolo} />
    }

    setContent(content)
  }

  useEffect(() => {
    parseContent()
  }, [placeholder])

  const classes = `custom-content ${placeholder.url ? 'active' : placeholder.key || ''}`

  return <div className={classes}>{content}</div>
})

export default Placeholder
