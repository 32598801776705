import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { SEARCH } from 'utils/queries'
import { RICERCA_ELEMENTS_PER_PAGE } from 'utils'
import { Page } from 'components/organisms'
import { useTrans } from 'utils/hooks'
import {
  ProdottoCard,
  TrattamentoCard,
  ArticoloMagazineCard,
  LineaHomeCard,
  Paginazione,
} from 'components/molecules'
import { trackingProductImpression } from 'utils/gtm'
import { InfiniteLoader } from 'components/atoms'

const base = 'ricerca__categorie__item'
const active = `${base}--active`
const initialModels = ['prodotto', 'linea', 'trattamento', 'articolomagazine']

const RicercaTemplate = ({ pagina, query, risultati, categorie }) => {
  const router = useRouter()

  const t = useTrans()
  const [currentPage, setCurrentPage] = useState(1)
  const [categoria, setCategoria] = useState(initialModels)
  const [models, setModels] = useState(initialModels)
  const [results, setResults] = useState(risultati)
  const [reset, setReset] = useState(false)
  const [paginator, setPaginator] = useState({ first: RICERCA_ELEMENTS_PER_PAGE })
  const { loading, data, refetch } = useQuery(SEARCH, {
    skip: !query,
    variables: {
      query,
      models,
      ...paginator,
    },
    fetchPolicy: 'cache-first',
  })

  const mounted = useRef(false)

  const handlePrevPage = () => {
    if (results?.pageInfo?.startCursor) {
      setCurrentPage(currentPage - 1)
      setPaginator({
        last: RICERCA_ELEMENTS_PER_PAGE,
        before: results.pageInfo.startCursor,
      })
    }
  }

  const handleNextPage = () => {
    if (results?.pageInfo?.endCursor) {
      setCurrentPage(currentPage + 1)
      setPaginator({
        first: RICERCA_ELEMENTS_PER_PAGE,
        after: results.pageInfo.endCursor,
      })
    }
  }

  useEffect(() => {
    setPaginator({ first: RICERCA_ELEMENTS_PER_PAGE })
    setModels(categoria)
    setReset(true)
  }, [categoria])

  useEffect(() => {
    if (query) refetch()
  }, [models, paginator])

  useEffect(() => {
    if (!loading && data?.search) {
      if (!mounted.current) {
        mounted.current = true
      } else {
        let searchResults = Object.assign({}, data.search)
        searchResults.edges = data.search.edges.map((item) => item.node)
        setResults(searchResults)
      }
    }
  }, [loading, data])

  useEffect(() => {
    if (results?.edges?.length) {
      const prodotti = results.edges.filter((item) => item.modelName === 'prodotto')
      if (prodotti.length) {
        trackingProductImpression(
          prodotti.map((item) => item.object),
          'search_results'
        )
      }
    }
  }, [results])

  return (
    <Page pagina={pagina} classes="ricerca">
      {categorie?.length > 0 && (results?.edges?.length > 0 || models) ? (
        <section className="ricerca__categorie">
          <Swiper
            spaceBetween={30}
            slidesPerView={5}
            breakpoints={{
              320: {
                slidesPerView: 2,
              },
              576: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 5,
              },
            }}
          >
            <SwiperSlide key={'all'}>
              <h2
                className={`${base} ${categoria === initialModels ? active : ''}`}
                onClick={() => setCategoria(null)}
              >
                {t('Tutte le categorie')}
              </h2>
            </SwiperSlide>
            {categorie.map((item) => (
              <SwiperSlide key={item?.id}>
                <h2
                  className={`${base} ${categoria === item.id ? active : ''}`}
                  onClick={() => setCategoria(item.id)}
                >
                  {item?.title}
                </h2>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      ) : null}

      {loading ? (
        <InfiniteLoader classes="icon--loader loading" />
      ) : results && results?.edges?.length > 0 ? (
        <>
          <div className="results">
            {results.edges.map((item, i) => {
              if (item.modelName === 'articolomagazine')
                return (
                  <article className="results__item" key={'articolomagazine_' + i}>
                    <ArticoloMagazineCard articolo={item.object} main={false} />
                  </article>
                )

              if (item.modelName === 'linea')
                return (
                  <div className="results__item results__item--linee" key={'linea_' + i}>
                    <LineaHomeCard linea={item.object} />
                  </div>
                )

              if (item.modelName === 'prodotto')
                return (
                  <div className="results__item" key={'prodotto_' + i}>
                    <ProdottoCard
                      prodotto={{ ...item.object, type: 'listing' }}
                      waPosition={i + 1}
                      waList="search_results"
                    />
                  </div>
                )

              if (item.modelName === 'trattamento')
                return (
                  <div className="results__item" key={'trattamento_' + i}>
                    <TrattamentoCard
                      trattamento={item.object}
                      onDetailClick={() => router.push(item.url)}
                      expanded={false}
                    />
                  </div>
                )
            })}
          </div>
          {results?.totalCount > RICERCA_ELEMENTS_PER_PAGE ? (
            <Paginazione
              currentPage={currentPage}
              results={results}
              reset={reset}
              setReset={setReset}
              loading={loading}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
            />
          ) : null}
        </>
      ) : (
        <div className="ricerca__empty">{t('Nessun risultato trovato')}</div>
      )}
    </Page>
  )
}

export default RicercaTemplate
