import React, { useEffect, useState } from 'react'
import { MyAccountBlock } from 'components/molecules'
import { FormLayout, FormField, FormInput, FormRadio, FormCheckbox } from 'components/atoms'
import { useDispatch, useSelector } from 'react-redux'
import FormDatiPersonali from 'components/organisms/FormDatiPersonali/FormDatiPersonali'
import FormPrivacy from 'components/organisms/FormPrivacy/FormPrivacy'
import FormPassword from 'components/organisms/FormPassword/FormPassword'

const MyAccountProfileTemplate = ({}) => {
  const [editPersonale, setEditPersonale] = useState(false)
  const [editPassword, setEditPassword] = useState(false)
  const [editPrivacy, setEditPrivacy] = useState(false)

  const { utente } = useSelector((state) => state.utente)

  return (
    <div className="myaccount-profile">
      {editPersonale ? (
        <FormDatiPersonali
          utente={utente}
          edit={editPersonale}
          setEdit={(val) => {
            setEditPersonale(val)
          }}
        />
      ) : (
        <MyAccountBlock
          title="Dati personali"
          edit={editPersonale}
          setEdit={(val) => {
            setEditPersonale(val)
          }}
        >
          <FormLayout>
            <FormField>
              <FormInput value={utente.nome} placeholder="Nome" disabled={true} />
            </FormField>
            <FormField>
              <FormInput value={utente.cognome} placeholder="Cognome" disabled={true} />
            </FormField>
            <FormField size="full" space={'md'}>
              <FormInput value={utente.telefono} placeholder="Numero di telefono" disabled={true} />
            </FormField>
            <FormField label="Data di nascita" size="full" variant="date" space={'md'}>
              <FormInput
                value={utente.dataNascita ? utente.dataNascita.split('-')[2] : ' '}
                placeholder="Giorno"
                disabled={true}
              />
              <FormInput
                value={utente.dataNascita ? utente.dataNascita.split('-')[1] : ' '}
                placeholder="Mese"
                disabled={true}
              />
              <FormInput
                value={utente.dataNascita ? utente.dataNascita.split('-')[0] : ' '}
                placeholder="Anno"
                disabled={true}
              />
            </FormField>
            <FormField label="Sesso" size="full" space={'md'}>
              <FormRadio
                value={utente.sesso}
                disabled={true}
                options={[
                  { label: 'Donna', value: 'd' },
                  { label: 'Uomo', value: 'u' },
                  { label: 'Preferisco non rispondere', value: 'x' },
                ]}
              />
            </FormField>
          </FormLayout>
        </MyAccountBlock>
      )}
      {editPassword ? (
        <FormPassword
          utente={utente}
          edit={editPassword}
          setEdit={(val) => {
            setEditPassword(val)
          }}
        />
      ) : (
        <MyAccountBlock
          title="Dati di accesso"
          edit={editPassword}
          setEdit={(val) => {
            setEditPassword(val)
          }}
        >
          <FormLayout>
            <FormField>
              <FormInput value={utente.email} placeholder="Email" type="email" disabled={true} />
            </FormField>
            <FormField>
              <FormInput value="*******" placeholder="Password" type="password" disabled={true} />
            </FormField>
          </FormLayout>
        </MyAccountBlock>
      )}
      {editPrivacy ? (
        <FormPrivacy
          utente={utente}
          edit={editPrivacy}
          setEdit={(val) => {
            setEditPrivacy(val)
          }}
        />
      ) : (
        <MyAccountBlock
          title="Informativa sulla privacy"
          edit={editPrivacy}
          setEdit={(val) => {
            setEditPrivacy(val)
          }}
        >
          <FormLayout>
            <FormField size="full" space={'lg'}>
              <FormCheckbox
                checked={utente.privacy}
                label="Fornisco il consenso per la registrazione come indicato nell’informativa"
              />
            </FormField>
            <FormField size="full" space={'none'}>
              <FormRadio
                value={utente.privacyCommerciale}
                disabled={true}
                description={
                  'Fornisco il consenso per finalità di Marketing come indicato nell’ Informativa e dichiaro di avere compiuto almeno 14 anni.'
                }
                options={[
                  { label: 'SI', value: 1 },
                  { label: 'NO', value: 0 },
                ]}
              />
            </FormField>
          </FormLayout>
        </MyAccountBlock>
      )}
    </div>
  )
}

export default MyAccountProfileTemplate
