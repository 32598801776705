import { CheckoutLayout, EsitoCheckout, IlTuoOrdine } from 'components/organisms'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

function CheckoutSuccessTemplate({ pagina, ordine }) {
  const [checkout, setCheckout] = useState(null)
  const [bonifico, setBonifico] = useState(false)

  const router = useRouter()

  useEffect(() => {
    handleBonifico()
  }, [router.query])

  useEffect(() => {
    handleCheckout()
    // window.addEventListener('beforeunload', cleanCheckout)
    // return () => window.removeEventListener('beforeunload', cleanCheckout)
  }, [])

  function handleBonifico() {
    if ('bonifico' in router.query) setBonifico(true)
  }

  function handleCheckout() {
    setCheckout(JSON.parse(localStorage.getItem('checkout')))
  }

  // function cleanCheckout() {
  //   localStorage.setItem('checkout', null)
  // }

  return (
    <CheckoutLayout
      pagina={pagina}
      data={checkout}
      loading={!checkout}
      main={
        <EsitoCheckout
          ordine={ordine}
          bonifico={bonifico}
          success={true}
          classes="checkout__esito"
        />
      }
      side={
        <IlTuoOrdine checkout={checkout} typ={true} ordine={ordine} classes="checkout__ordine" />
      }
      classes="checkout-success"
    />
  )
}

export default CheckoutSuccessTemplate
