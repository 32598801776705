import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { Button, ProdottoCard } from 'components'
import { Page } from 'components/organisms'
import { seoMock } from 'utils/mock'
import { useTrans } from 'utils/hooks'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
SwiperCore.use([Pagination])

const Custom404Template = ({}) => {
  const t = useTrans()

  const { loading, error, data } = useQuery(
    gql`
      query {
        prodotti(inHome: true, last: 3) {
          edges {
            node {
              pk
              titolo
              sottotitolo
              codice
              prodottoCardImageThumb
              url
              prezzo
              prezzoScontato
              scontoPercentuale
              wishlist
              disponibile
              linea {
                colore
                nome
              }
              categoria {
                titolo
              }
              promoApplicata {
                descrizione
              }
            }
          }
        }
      }
    `,
    { fetchPolicy: 'cache-first' }
  )

  const pagina = {
    seo: seoMock, // TODO
    titolo: t('page404__titolo'),
    descrizione: t('page404__descrizione'),
    breadcrumbs: [{ titolo: 'Home', url: '/' }, { titolo: '404' }],
  }

  return (
    <Page pagina={pagina} classes="page404">
      <div className="page404__back">
        <Button type="primary" href="/" label={t('Torna alla home')} />
      </div>
      {data && data.prodotti && data.prodotti.edges.length > 0 && (
        <div className="page404__products swiper--with-pagination">
          <Swiper
            pagination={{ clickable: true }}
            slidesPerView={3}
            spaceBetween={16}
            watchOverflow
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              375: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {data.prodotti.edges.map((prodottoNode, index) => (
              <SwiperSlide key={index}>
                <ProdottoCard prodotto={prodottoNode.node} waPosition={index + 1} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </Page>
  )
}

export default Custom404Template
