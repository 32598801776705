import { WContainer } from 'components/atoms'
import { FormRegistrazione, Page } from 'components/organisms'

function RegistrazioneTemplate({ pagina }) {
  return (
    <Page pagina={pagina} classes="registrazione">
      <WContainer inner>
        <FormRegistrazione />
      </WContainer>
    </Page>
  )
}

export default RegistrazioneTemplate
