import { Button } from 'components/atoms'
import { BannerLanding, GenericStep } from 'components/molecules'
import { FormDiventaCentroEstetico } from 'components/organisms'
import React from 'react'
import { trackingGAevent } from 'utils/gtm'
import { useSelector } from 'react-redux'
import { useTrans } from 'utils/hooks'

const DiventaUnCentroTemplate = ({ pagina }) => {
  const { menu } = useSelector((state) => state.menu)
  const menu_banner_landing = menu?.edges?.find((item) => item.node.key === 'banner_landing')
  const menu_scegliere_matis = menu?.edges?.find((item) => item.node.key === 'scegliere_matis')
  const t = useTrans()
  return (
    <>
      <div className="page diventa-un-centro-template">
        {menu_banner_landing?.node ? <BannerLanding menu={menu_banner_landing.node} /> : null}
        <div className="diventa-un-centro-template__form_container" id="container_form">
          <div className="diventa-un-centro-template__image"></div>
          <div className="diventa-un-centro-template__form">
            <p className="diventa-un-centro-template__form_title">
              {t('Hai bisogno di informazioni? Scrivici compilando il form.')}
            </p>
            <FormDiventaCentroEstetico />
          </div>
        </div>
        {menu_scegliere_matis?.node?.children?.edges?.length ? (
          <GenericStep menu={menu_scegliere_matis.node} />
        ) : null}
        <div className="diventa-un-centro-template__button">
          <Button
            href="#container_form"
            label={t('Diventa un centro Matis Paris')}
            type="primary"
            size="sm"
            onClick={() => {
              trackingGAevent('02', 'landing', 'diventa-un-centro', 'cta_diventa_un_centro')
            }}
          />
        </div>
      </div>
    </>
  )
}

export default DiventaUnCentroTemplate
