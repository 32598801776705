import React from 'react'
import { Accordion, JsonLd } from 'components/atoms'
import { BoxContatti } from 'components/molecules'
import { Page } from 'components/organisms'

const FaqTemplate = ({ pagina, categorieFaq }) => {
  return (
    <Page pagina={pagina} classes="faq-page" noContainer={true}>
      <JsonLd item={categorieFaq} type="faq" />
      {categorieFaq && categorieFaq.length > 0 && (
        <div className="faq-page__categories">
          {categorieFaq.map((categoria, i) => (
            <div className="faq-category" key={'categoria-faq_' + i.toString()}>
              <p className="faq-category__title">{categoria.nome}</p>
              <div className="faq-category__faqs">
                {categoria.faq &&
                  categoria.faq?.length > 0 &&
                  categoria.faq.map((faq, j) => (
                    <Accordion
                      key={'faq_' + i.toString() + '-' + j}
                      placeholder={faq.domanda}
                      id="31"
                      category="faq"
                      action={categoria.nome}
                      label={faq.domanda}
                    >
                      {faq.risposta}
                    </Accordion>
                  ))}
              </div>
            </div>
          ))}
        </div>
      )}
      <BoxContatti />
    </Page>
  )
}

export default FaqTemplate
