import { FormLogin, Page } from 'components/organisms'

function LoginTemplate({ pagina }) {
  return (
    <Page pagina={pagina} classes="login">
      <FormLogin />
    </Page>
  )
}

export default LoginTemplate
