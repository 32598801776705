import React from 'react'
import dynamic from 'next/dynamic'
import parse from 'html-react-parser'
const PagebuilderSlider = dynamic(() =>
  import('components/atoms/PagebuilderSlider/PagebuilderSlider')
)

// DOCS: https://github.com/remarkablemark/html-react-parser

const PlaceholderContent = React.memo(({ content, titoloArticolo }) => {
  const getSlides = (children) => {
    return children.map((node) => {
      return node.attribs?.src ? { image: node.attribs?.src } : null
    })
  }

  return parse(content, {
    replace: (node) => {
      if (node.attribs) {
        if (node.attribs['class'] === 'fake-gallery') {
          return (
            <PagebuilderSlider slides={getSlides(node.children)} titoloArticolo={titoloArticolo} />
          )
        }
      }
    },
  })
})

export default PlaceholderContent
