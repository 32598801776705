import React from 'react'
import { MyAccountBlock, OrdineDatatable } from 'components/molecules'
import { MyAccountOrdiniDetail } from 'components/organisms'
import { useTrans } from 'utils/hooks'

const MyAccountOrdiniTemplate = ({ ordini, ordineAttivo }) => {
  const t = useTrans()
  return (
    <div className="myaccount-ordini">
      {ordineAttivo ? (
        <MyAccountBlock>
          <MyAccountOrdiniDetail ordine={ordineAttivo} isIndex={true} />
        </MyAccountBlock>
      ) : null}
      <MyAccountBlock title={t('I miei acquisti precedenti')}>
        {ordini.length ? (
          <OrdineDatatable ordini={ordini} />
        ) : ordineAttivo ? (
          <p className="myaccount-ordini__empty">{t('Nessun ordine precedente.')}</p>
        ) : (
          <p className="myaccount-ordini__empty">{t('Non hai ancora effettuato nessun ordine.')}</p>
        )}
      </MyAccountBlock>
    </div>
  )
}

export default MyAccountOrdiniTemplate
