import { CheckoutLayout, EsitoCheckout, IlTuoOrdine } from 'components/organisms'
import { useEffect, useState } from 'react'

function CheckoutErrorTemplate({ pagina }) {
  const [checkout, setCheckout] = useState(null)

  useEffect(() => {
    handleCheckout()
  }, [])

  function handleCheckout() {
    setCheckout(JSON.parse(localStorage.getItem('checkout')))
  }

  return (
    <CheckoutLayout
      pagina={pagina}
      data={checkout}
      loading={!checkout}
      main={<EsitoCheckout bonifico={false} success={false} classes="checkout__esito" />}
      side={<IlTuoOrdine checkout={checkout} typ={true} classes="checkout__ordine" />}
      classes="checkout-error"
    />
  )
}

export default CheckoutErrorTemplate
