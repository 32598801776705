import { MetaSeo } from 'components/atoms'
import { BoxAziendaSlider, BoxHero, BoxMarketing2 } from 'components/molecules'
import React from 'react'

const AziendaTemplate = ({ pagina, aziendaData }) => {
  return (
    <div className="page azienda-template">
      <MetaSeo seo={pagina.seo} />
      {aziendaData.hero && (
        <BoxHero
          title={aziendaData.hero.titolo}
          text={aziendaData.hero.testo}
          image={aziendaData.hero.image}
          breadcrumbs={pagina.breadcrumbs}
        />
      )}
      {aziendaData.boxMarketing2.length > 0 && (
        <BoxMarketing2 box={aziendaData.boxMarketing2[0]} noPadding />
      )}
      {aziendaData.boxStoria && <BoxAziendaSlider box={aziendaData.boxStoria} />}
      {aziendaData.boxMarketing2.length > 1 && (
        <BoxMarketing2 box={aziendaData.boxMarketing2[1]} noPadding />
      )}
      {aziendaData.boxAzienda && <BoxAziendaSlider box={aziendaData.boxAzienda} />}
      {aziendaData.boxMarketing2.length > 2 && (
        <BoxMarketing2 box={aziendaData.boxMarketing2[2]} noPadding />
      )}
    </div>
  )
}

export default AziendaTemplate
