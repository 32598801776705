import { Page } from 'components/organisms'
import { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ArticoloMagazineCard } from 'components/molecules'
import { trackingGAevent } from 'utils/gtm'

const base = 'magazine__categorie__item'
const active = `${base}--active`

function MagazineTemplate({ articoli, categorie, pagina }) {
  const [articoliFiltered, setArticoliFiltered] = useState(articoli)
  const [categoria, setCategoria] = useState(null)

  const mounted = useRef(false)

  useEffect(() => {
    if (window.location.hash) {
      const categoria = categorie.edges.find(
        (c) => c.node.slug === window.location.hash.replace('#', '')
      )
      if (categoria) setCategoria(categoria.node.title)
    }
  }, [])

  useEffect(() => {
    if (mounted.current) filterArticoli(categoria)
    else mounted.current = true
  }, [categoria])

  const filterArticoli = (categoria) => {
    if (categoria) {
      const filtered = articoli.filter(({ node }) => node.categoria?.title === categoria)
      setArticoliFiltered(filtered)
    } else {
      setArticoliFiltered(articoli)
    }
  }

  return (
    <Page pagina={pagina} classes="magazine">
      <section className="magazine__categorie">
        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          breakpoints={{
            320: {
              slidesPerView: 1.5,
            },
            576: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
          }}
        >
          <SwiperSlide key={'all'}>
            <h3
              className={`${base} ${!categoria ? active : ''}`}
              onClick={() => setCategoria(null)}
            >
              Tutte le categorie
            </h3>
          </SwiperSlide>
          {categorie.edges.map(({ node }) => (
            <SwiperSlide key={node.title}>
              <h3
                className={`${base} ${categoria === node.title ? active : ''}`}
                onClick={() => setCategoria(node.title)}
              >
                {node.title}
              </h3>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <section className="magazine__articoli">
        {articoliFiltered.map(({ node }, index) => (
          <article
            className={`magazine__articolo ${index === 0 ? ' magazine__articolo--main' : ''}`}
            key={'articolo_' + index.toString()}
          >
            <ArticoloMagazineCard
              articolo={node}
              main={index === 0}
              onClick={() => trackingGAevent(46, 'blog', 'clic-scopri-di-piu', node.url)}
            />
          </article>
        ))}
      </section>
    </Page>
  )
}

export default MagazineTemplate
