import { Button, InfiniteLoader, NotificationBar } from 'components/atoms'
import { Page, RigheCarrello } from 'components/organisms'
import { useContext, useEffect } from 'react'
import { FREE_SHIPPING } from 'utils'
import { CartContext, initialCart } from 'utils/context'
import { priceNotation } from 'utils/safe'
import { trackingCheckout, trackingGAevent } from 'utils/gtm'

function CartTemplate({ pagina }) {
  const context = useContext(CartContext)
  const isLoading = context ? context.isLoading : false
  const cart = context ? context.cart : initialCart

  let leftForFreeShipping = 0
  if (cart.totaleProdottiScontato < FREE_SHIPPING)
    leftForFreeShipping = FREE_SHIPPING - cart.totaleProdottiScontato

  useEffect(() => {
    trackingCheckout(cart, 1, '')
  }, [])

  return (
    <>
      <Page classes="cart" pagina={pagina}>
        <div className="cart__content">
          {cart.items.length ? (
            <div>
              {leftForFreeShipping ? (
                <NotificationBar
                  type="error"
                  classes="cart__alert notification-bar--grey"
                  placeholder={`Ti mancano ancora € ${leftForFreeShipping.toFixed(
                    2
                  )} per avere diritto alla spedizione standard gratuita!`}
                />
              ) : null}
              <span className="cart__num-prodotti">
                {cart.numProdotti} {cart.numProdotti === 1 ? 'prodotto' : 'prodotti'}
              </span>
              <RigheCarrello items={cart.items} />
              <section className="cart__total">
                <span className="cart__total__label">Subtotale</span>
                <span className="cart__total__price">
                  {priceNotation(cart.totaleProdottiScontato)}
                </span>
              </section>
              <section className="cart__link">
                <Button
                  type="ghost"
                  href="/"
                  label="Continua gli acquisti"
                  onClick={() => trackingGAevent(17, 'cart', 'click', 'continua_gli_acquisti')}
                />
                <Button
                  href="/cart/checkout"
                  label="Vai al checkout"
                  onClick={() => trackingGAevent(17, 'cart', 'click', 'vai_al_checkout')}
                />
              </section>
            </div>
          ) : isLoading ? (
            <InfiniteLoader classes="icon--loader loading" />
          ) : (
            <p className="cart__empty">Il tuo carrello è vuoto.</p>
          )}
        </div>
      </Page>
      {cart.items.length ? (
        <section className="cart__sticky">
          <p className="cart__sticky__total">
            <span className="cart__sticky__total__label">Totale</span>
            <span className="cart__sticky__total__price">
              {priceNotation(cart.totaleProdottiScontato)}
            </span>
          </p>
          <Button href="/cart/checkout" label="Vai al checkout" classes="cart__sticky__cta" />
        </section>
      ) : isLoading ? null : (
        <section className="cart__sticky">
          <Button
            type="secondary"
            href="/"
            label="Continua gli acquisti"
            classes="cart__sticky__cta"
          />
        </section>
      )}
    </>
  )
}

export default CartTemplate
