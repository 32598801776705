import { useQuery } from '@apollo/client'
import {
  CentroEsteticoCard,
  GoogleMapMarkerInfo,
  GoogleMapWrap,
  MyAccountBlock,
} from 'components/molecules'
import { FormCentroEstetico } from 'components/organisms'
import { CENTRO_ESTETICO } from 'utils/queries'
import { GOOGLE_MAPS_URL } from 'utils'
import React, { useEffect, useState } from 'react'

const MyAccountCentroEsteticoTemplate = ({}) => {
  const [edit, setEdit] = useState(false)
  const [centro, setCentro] = useState(null)
  const [mapCoords, setMapCoords] = useState({
    lat: 44.7096,
    lng: 10.6205,
  })

  const { data: centroEsteticoData } = useQuery(CENTRO_ESTETICO)

  useEffect(() => {
    if (centroEsteticoData?.me.centroEstetico) {
      setCentro(centroEsteticoData.me.centroEstetico)
      if (
        centroEsteticoData.me.centroEstetico.latitudine &&
        centroEsteticoData.me.centroEstetico.longitudine
      )
        setMapCoords({
          lat: centroEsteticoData.me.centroEstetico.latitudine,
          lng: centroEsteticoData.me.centroEstetico.longitudine,
        })
    }
  }, [centroEsteticoData])

  return (
    <div className="myaccount-centroestetico-template">
      {edit ? (
        <FormCentroEstetico setEdit={setEdit} />
      ) : (
        <MyAccountBlock
          title="Il mio centro estetico"
          edit={edit}
          myAccount
          setEdit={(val) => {
            setEdit(val)
          }}
        >
          <div className="centro-estetico">
            {centro && <CentroEsteticoCard centroEstetico={centro} myAccount />}
            <GoogleMapWrap
              options={{ disableDefaultUI: true }}
              googleMapURL={GOOGLE_MAPS_URL}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div className={'centro-estetico__map '} style={{ height: `100vh` }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
              mapCoords={mapCoords}
              items={centro}
            >
              {centro && (
                <GoogleMapMarkerInfo
                  key={`centro-${centro.pk}`}
                  centro={centro}
                  setSelectedCentro={(centro) => null}
                  selectedCentro={null}
                  setMapCoords={setMapCoords}
                  myAccount
                />
              )}
            </GoogleMapWrap>
          </div>
        </MyAccountBlock>
      )}
    </div>
  )
}

export default MyAccountCentroEsteticoTemplate
