import { Button, FormField, FormInput, NotificationBar } from 'components/atoms'
import { Page } from 'components/organisms'
import { Formik } from 'formik'
import { useTrans } from 'utils/hooks'
import { resetPassword } from 'utils/validation'
import { RESET_PASSWORD } from 'utils/queries'
import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'

function ResetPasswordTemplate({ pagina, token }) {
  const t = useTrans()

  const [error, setError] = useState('')
  const [status, setStatus] = useState(false)

  const [submit, { data, loading }] = useMutation(RESET_PASSWORD, { errorPolicy: 'all' })

  useEffect(() => {
    if (data) handleStatus(data?.resetPassword.status)
  }, [data])

  function handleStatus(status) {
    if (status) {
      setStatus(true)
      setError('')
    } else {
      setError(t('Token non valido'))
      setStatus(false)
    }
  }

  return (
    <Page pagina={pagina} classes="reset-password">
      <Formik
        validationSchema={resetPassword}
        isInitialValid={false}
        initialValues={{
          password: '',
          password2: '',
        }}
        onSubmit={async (values) => {
          await submit({
            variables: {
              password: values.password,
              token,
            },
          })
        }}
      >
        {(props) => (
          <div className="reset-password__form">
            <NotificationBar
              type="error"
              placeholder={error}
              classes={`reset-password__notice ${error ? 'visible' : 'hidden'}`}
            />
            {!status ? (
              <>
                <FormField
                  status={
                    props.touched.password ? (props.errors.password ? 'error' : 'success') : null
                  }
                  errorMessage={props.errors?.password}
                  className="reset-password__field"
                >
                  <FormInput
                    onChange={props.handleChange('password')}
                    onBlur={props.handleBlur('password')}
                    placeholder="Nuova password"
                    type="password"
                  />
                </FormField>
                <FormField
                  status={
                    props.touched.password2 ? (props.errors.password2 ? 'error' : 'success') : null
                  }
                  errorMessage={props.errors?.password2}
                  className="reset-password__field"
                >
                  <FormInput
                    placeholder="Conferma nuova password"
                    onChange={props.handleChange('password2')}
                    onBlur={props.handleBlur('password2')}
                    type="password"
                  />
                </FormField>
                <Button
                  label="Conferma password"
                  disabled={!props.isValid}
                  inputType="submit"
                  onClick={() => props.handleSubmit()}
                />
              </>
            ) : (
              <>
                <p className="reset-password__disclaimer">
                  {t('La tua password è stata modificata')}
                </p>
                <Button label="Vai al tuo account" href="/myaccount/profilo" />
              </>
            )}
          </div>
        )}
      </Formik>
    </Page>
  )
}

export default ResetPasswordTemplate
