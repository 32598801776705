import React, { useEffect, useState } from 'react'
import { GoogleMapWrap, GoogleMapMarkerInfo, Breadcrumbs } from 'components/molecules'
import {
  FormSelect,
  Button,
  FormField,
  WContainer,
  MetaSeo,
  InfiniteLoader,
} from 'components/atoms'
import { Page } from 'components/organisms'
import { GOOGLE_MAPS_URL } from 'utils'
import { useTrans, useIsMobile } from 'utils/hooks'
import { Crosshair, List, PinEmpty } from 'components/atoms/Icons'
import { CentroEsteticoCard } from 'components/molecules'
import Head from 'next/head'
import { trackingGAevent } from 'utils/gtm'

const initialMapCoords = {
  lat: 44.7096,
  lng: 10.6205,
}

const CentriEsteticiTemplate = ({ pagina, centriEstetici }) => {
  const t = useTrans()
  const isMobile = useIsMobile()

  const [selectedCentro, setSelectedCentro] = useState(null)
  const [mapCoords, setMapCoords] = useState(initialMapCoords)
  const [province, setProvince] = useState([])
  const [filters, setFilters] = useState({ provincia: '', citta: '' })
  const [centriFiltrati, setCentriFiltrati] = useState([])
  const [listaToogle, setListaToogle] = useState(false)

  const initProvince = (centri) => {
    const province = new Set(centriEstetici.map((centro) => centro.provincia).sort())
    setProvince([...province])
  }

  const getCitta = (provincia) => {
    const centriPerProvincia = centriEstetici.filter((centro) => centro.provincia === provincia)
    const citta = new Set(centriPerProvincia.map((centro) => centro.citta).sort())
    return [...citta].map((c) => ({ value: c, label: c, selected: false }))
  }

  const getNearBy = (position, soglia) => {
    const R = 6371
    const rad = (x) => (x * Math.PI) / 180

    return centriEstetici.filter((centro) => {
      const mlat = centro.latitudine
      const mlng = centro.longitudine
      const dLat = rad(mlat - position.latitudine)
      const dLong = rad(mlng - position.longitudine)
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(position.latitudine)) *
          Math.cos(rad(position.latitudine)) *
          Math.sin(dLong / 2) *
          Math.sin(dLong / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      const d = R * c

      return d <= soglia
    })
  }

  const geolocalize = () => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const centriVicini = getNearBy(
          {
            latitudine: position.coords.latitude,
            longitudine: position.coords.longitude,
          },
          20
        )
        setCentriFiltrati(centriVicini)
      },
      function (error) {
        console.error('Error Code = ' + error.code + ' - ' + error.message)
      }
    )
  }

  useEffect(() => {
    if (centriEstetici?.length) initProvince(centriEstetici)
  }, [centriEstetici])

  useEffect(() => {
    let centri = centriEstetici
    if (filters.provincia) {
      centri = centri.filter((centro) => centro.provincia === filters.provincia)
    }
    if (filters.citta) {
      centri = centri.filter((centro) => centro.citta === filters.citta)
    }
    setCentriFiltrati(centri)
  }, [filters])

  useEffect(() => {
    setSelectedCentro(null)
  }, [centriFiltrati])

  return (
    <div className="page centri-estetici-template">
      {pagina?.seo && <MetaSeo seo={pagina.seo} />}
      <Head>
        <link rel="preconnect" href="https://maps.googleapis.com" />
        <link rel="preconnect" href="https://maps.gstatic.com" />
      </Head>
      <WContainer>
        <div className="page__head">
          {pagina?.breadcrumbs && <Breadcrumbs breadcrumbs={pagina.breadcrumbs} />}
          <Page.Title title={pagina?.titolo} />
          <Page.Description description={pagina?.descrizione} />
        </div>
      </WContainer>
      <div className="centri-estetici-form">
        <FormField>
          <FormSelect
            name="provincia"
            options={province.map((p) => ({ value: p, label: p }))}
            placeholder="Provincia"
            onChange={({ value }) => {
              setFilters({ citta: '', provincia: value })
              trackingGAevent(2, 'store_locator', 'filter_provincia', value)
            }}
          />
        </FormField>
        <FormField>
          {filters?.provincia ? (
            <FormSelect
              value={filters.citta ? { value: filters.citta, label: filters.citta } : null}
              name="citta"
              options={getCitta(filters.provincia)}
              placeholder="Città"
              onChange={({ value }) => {
                setFilters({ ...filters, citta: value })
                trackingGAevent(
                  2,
                  'store_locator',
                  'filter_provincia_citta',
                  filters.provincia + '_' + value
                )
              }}
            />
          ) : null}
        </FormField>
        <Button
          type="ghost"
          icon={<Crosshair />}
          label={t('Cerca vicino a me')}
          classes="centri-estetici-form__submit geolocation hide-mobile"
          onClick={() => geolocalize()}
        />
      </div>

      <div className="storelocator">
        <div className="storelocator__tabs">
          <Button
            classes={listaToogle ? '' : 'active'}
            type="ghost"
            icon={<PinEmpty />}
            label={t('Mappa')}
            onClick={() => {
              setListaToogle(false)
              trackingGAevent(24, 'store_locator', 'view', 'map')
            }}
          />
          <Button
            classes={listaToogle ? 'active' : ''}
            type="ghost"
            icon={<List />}
            label={t('Lista')}
            onClick={() => {
              setListaToogle(true)
              trackingGAevent(24, 'store_locator', 'view', 'listing')
            }}
          />
        </div>
        <div className={`storelocator__panel ` + (listaToogle ? '' : 'hide-mobile')}>
          <div className="storelocator__panel__head">
            <span className="dismiss">
              {centriFiltrati?.length} {t('centri estetici trovati')}
            </span>
          </div>

          <main className="storelocator__panel__list">
            {centriFiltrati.map((centro, i) => (
              <CentroEsteticoCard centroEstetico={centro} key={'centro_' + i} />
            ))}
          </main>
        </div>

        <GoogleMapWrap
          options={{ disableDefaultUI: true }}
          googleMapURL={GOOGLE_MAPS_URL}
          loadingElement={<InfiniteLoader classes="icon--loader loading" />}
          containerElement={
            <div className={'storelocator__map ' + (listaToogle ? 'hide-mobile' : '')} />
          }
          mapElement={<div style={{ height: `100%` }} />}
          mapCoords={mapCoords}
          items={centriFiltrati}
        >
          <Button
            type="ghost"
            icon={<Crosshair color="#8F405D" />}
            label={''}
            classes="geolocation geolocation__mobile"
            onClick={() => geolocalize()}
          />
          {centriFiltrati.map((centro) => (
            <GoogleMapMarkerInfo
              key={`centro-${centro.pk}`}
              centro={centro}
              setSelectedCentro={setSelectedCentro}
              selectedCentro={selectedCentro}
              setMapCoords={setMapCoords}
            />
          ))}
        </GoogleMapWrap>
        {isMobile && selectedCentro && !listaToogle ? (
          <div className="centro-card-mobile">
            <CentroEsteticoCard centroEstetico={selectedCentro} iconUp={true} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default CentriEsteticiTemplate
