import React, { useEffect, useState } from 'react'
import { Plus } from 'components/atoms/Icons'
import { Button } from 'components/atoms'
import { MyAccountBlock, IndirizzoCard } from 'components/molecules'
import { INDIRIZZI_FATTURAZIONE, INDIRIZZI_SPEDIZIONE } from 'utils/queries'
import { useQuery } from '@apollo/client'
import MyAccountIndirizzoEditForm from 'components/organisms/MyAccountIndirizzoEditForm/MyAccountIndirizzoEditForm'
import MyAccountIndirizzoAddForm from 'components/organisms/MyAccountIndirizzoAddForm/MyAccountIndirizzoAddForm'
import { trackingGAevent } from 'utils/gtm'

const MyAccountIndirizziTemplate = ({}) => {
  const [indirizziSpedizione, setIndirizziSpedizione] = useState([])
  const [indirizziFatturazione, setIndirizziFatturazione] = useState([])
  const [addSpedizioneOpen, setAddSpedizioneOpen] = useState(false)
  const [addFatturazioneOpen, setAddFatturazioneOpen] = useState(false)

  const { loading: indirizziSpedizioneLoading, data: indirizziSpedizioneData } =
    useQuery(INDIRIZZI_SPEDIZIONE)
  const { loading: indirizziFatturazioneLoading, data: indirizziFatturazioneData } =
    useQuery(INDIRIZZI_FATTURAZIONE)

  useEffect(() => {
    if (indirizziSpedizioneData?.indirizzi)
      setIndirizziSpedizione(indirizziSpedizioneData.indirizzi.edges.map(({ node }) => node))
  }, [indirizziSpedizioneData])

  useEffect(() => {
    if (indirizziFatturazioneData?.indirizzi)
      setIndirizziFatturazione(indirizziFatturazioneData.indirizzi.edges.map(({ node }) => node))
  }, [indirizziFatturazioneData])

  return (
    <div className="myaccount-indirizzi">
      <MyAccountBlock title="Indirizzi di spedizione">
        {indirizziSpedizione.length ? (
          <div className="myaccount-indirizzi__list">
            {indirizziSpedizione?.map((indirizzo) => (
              <MyAccountIndirizzo key={indirizzo.pk} indirizzo={indirizzo} />
            ))}
          </div>
        ) : (
          <p className="myaccount-indirizzi__empty">Non hai ancora aggiunto nessun indirizzo</p>
        )}
        {addSpedizioneOpen ? (
          <MyAccountIndirizzoAddForm isFatturazione={false} setEdit={setAddSpedizioneOpen} />
        ) : (
          <a
            onClick={() => {
              trackingGAevent(33, 'shipping_address', 'add', 'my_account')
            }}
          >
            <Button
              label="Aggiungi indirizzo di spedizione"
              size="md"
              type="secondary"
              icon={<Plus />}
              classes="myaccount-indirizzi__add-button"
              onClick={() => setAddSpedizioneOpen(true)}
            />
          </a>
        )}
      </MyAccountBlock>
      <MyAccountBlock title="Indirizzi di fatturazione">
        {indirizziFatturazione.length ? (
          <div className="myaccount-indirizzi__list">
            {indirizziFatturazione?.map((indirizzo) => (
              <MyAccountIndirizzo key={indirizzo.pk} indirizzo={indirizzo} isFatturazione />
            ))}
          </div>
        ) : (
          <p className="myaccount-indirizzi__empty">Non hai ancora aggiunto nessun indirizzo</p>
        )}
        {addFatturazioneOpen ? (
          <MyAccountIndirizzoAddForm isFatturazione={true} setEdit={setAddFatturazioneOpen} />
        ) : (
          <Button
            label="Aggiungi indirizzo di fatturazione"
            size="md"
            type="secondary"
            icon={<Plus />}
            classes="myaccount-indirizzi__add-button"
            onClick={() => setAddFatturazioneOpen(true)}
          />
        )}
      </MyAccountBlock>
    </div>
  )
}

const MyAccountIndirizzo = ({ indirizzo, isFatturazione = false }) => {
  const [edit, setEdit] = useState(false)
  return (
    <div className="myaccount-indirizzi__item">
      {edit ? (
        <MyAccountIndirizzoEditForm
          indirizzo={indirizzo}
          setEdit={setEdit}
          fatturazione={isFatturazione}
        />
      ) : (
        <IndirizzoCard
          indirizzo={indirizzo}
          editable
          onEdit={() => setEdit(true)}
          fatturazione={isFatturazione}
        />
      )}
    </div>
  )
}

export default MyAccountIndirizziTemplate
