import { BoxHelpContatti } from 'components/molecules'
import { FormContatti, Page } from 'components/organisms'
import React from 'react'

const ContattiTemplate = ({ pagina }) => {
  return (
    <>
      <Page pagina={pagina}>
        <FormContatti />
      </Page>
      <div className="contatti-template__box">
        <BoxHelpContatti />
      </div>
    </>
  )
}

export default ContattiTemplate
