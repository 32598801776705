import { useMutation } from '@apollo/client'
import { Button, Divider, FormField, FormInput, NotificationBar } from 'components/atoms'
import { Page } from 'components/organisms'
import { Formik } from 'formik'
import { useTrans } from 'utils/hooks'
import { recuperaPassword } from 'utils/validation'
import { RECUPERA_PASSWORD } from 'utils/queries'
import { useEffect, useState } from 'react'
import { trackingGAevent } from 'utils/gtm'

function RecuperaPasswordTemplate({ pagina }) {
  const t = useTrans()

  const [error, setError] = useState('')
  const [status, setStatus] = useState('')

  const [submit, { data, loading }] = useMutation(RECUPERA_PASSWORD, { errorPolicy: 'all' })

  useEffect(() => {
    if (data) handleStatus(data?.recuperaPassword.status)
  }, [data])

  function handleStatus(status) {
    if (status) {
      setStatus(t('Abbiamo inviato una mail all’indirizzo indicato'))
      setError('')
      trackingGAevent(7, 'user', 'forgot_password', '')
    } else {
      setError(t('Non è stato trovato nessun account con questo indirizzo'))
      setStatus('')
    }
  }

  return (
    <Page pagina={pagina} classes="recupera-password">
      <Formik
        validationSchema={recuperaPassword}
        isInitialValid={false}
        initialValues={{
          email: '',
        }}
        onSubmit={async (values) => {
          await submit({
            variables: {
              email: values.email,
            },
          })
        }}
      >
        {(props) => (
          <div className="recupera-password__form">
            <NotificationBar
              type="success"
              placeholder={status}
              classes={`recupera-password__notice ${status ? 'visible' : 'hidden'}`}
            />
            <NotificationBar
              type="error"
              placeholder={error}
              classes={`recupera-password__notice ${error ? 'visible' : 'hidden'}`}
            />
            {!status ? (
              <>
                <FormField
                  status={props.touched.email ? (props.errors.email ? 'error' : 'success') : null}
                  errorMessage={props.errors?.email}
                  className="recupera-password__field"
                >
                  <FormInput
                    onChange={props.handleChange('email')}
                    onBlur={props.handleBlur('email')}
                    placeholder="Email*"
                    type="email"
                  />
                </FormField>
                <Button
                  label="Invia link di recupero"
                  disabled={!props.isValid || loading}
                  inputType="submit"
                  onClick={() => props.handleSubmit()}
                />
              </>
            ) : (
              <>
                <p className="recupera-password__disclaimer">
                  {t('Segui le istruzioni indicate sulla mail per recuperare la password.')}
                </p>
                <Divider />
                <p className="recupera-password__help">{t('Non hai ricevuto la mail? ')}</p>
                <Button
                  label="Invia di nuovo"
                  inputType="submit"
                  onClick={() => props.handleSubmit()}
                />
              </>
            )}
          </div>
        )}
      </Formik>
    </Page>
  )
}

export default RecuperaPasswordTemplate
