import { MetaSeo } from 'components'
import Head from 'next/head'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { trackingGAevent } from 'utils/gtm'
import { ArrowRight } from '../../atoms/Icons/index'
import { Button, Label, WContainer, ImageBox, JsonLd, InfiniteLoader } from '../../atoms/index'
import { ArticoloMagazineCard, Breadcrumbs } from '../../molecules'
import Placeholder from '../../molecules/Placeholder/Placeholder'

SwiperCore.use([Pagination])

function ArticoloTemplate({ articolo, content, pagina }) {
  return (
    <main className="page articolo-detail">
      {articolo.mainImageThumb ? (
        <Head>
          <link rel="preload" href={articolo.mainImageThumb} as="image" />
        </Head>
      ) : null}
      <MetaSeo seo={pagina.seo} article={articolo} />
      <JsonLd item={articolo} type="articolo" />
      <section className="articolo-detail__top">
        <WContainer>
          <Breadcrumbs
            breadcrumbs={pagina.breadcrumbs}
            white
            classes="articolo-detail__breadcrumbs"
          />
          <div className="articolo-detail__head">
            <div className="articolo-detail__head__content">
              <Label label={articolo.categoria?.title} classes="articolo-detail__category" />
              <h1 className="articolo-detail__title">{articolo.title}</h1>
              <p className="articolo-detail__date">{articolo.publicationDate}</p>
            </div>
            <ImageBox
              src={articolo.mainImageThumb}
              maxWidth={560}
              maxHeight={800}
              classPrefix="articolo-detail__head"
              alt={articolo.title.toAltTitle()}
              title={articolo.title.toAltTitle()}
            />
          </div>
        </WContainer>
      </section>
      <section className="articolo-detail__content">
        {content ? (
          <Placeholder placeholder={content} titoloArticolo={articolo.title} />
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <InfiniteLoader />
          </div>
        )}
      </section>
      {articolo.prev || articolo.next ? (
        <WContainer inner>
          <section className="articolo-detail__links">
            {articolo.prev && (
              <Button
                type="ghost"
                icon={<ArrowRight />}
                label="Precedente"
                href={articolo.prev.url}
                classes="articolo-detail__links__prev"
                onClick={() =>
                  trackingGAevent(
                    51,
                    'blog',
                    'esplora_altri_articoli',
                    'clic_frecce' + '-' + articolo.title
                  )
                }
              />
            )}
            {articolo.next && (
              <Button
                type="ghost"
                icon={<ArrowRight />}
                label="Successivo"
                iconPos="right"
                href={articolo.next.url}
                classes={`articolo-detail__links__next ${
                  articolo.prev ? '' : 'articolo-detail__links__next--right'
                }`}
                onClick={() =>
                  trackingGAevent(
                    51,
                    'blog',
                    'esplora_altri_articoli',
                    'clic_frecce' + '-' + articolo.title
                  )
                }
              />
            )}
          </section>
        </WContainer>
      ) : null}
      {articolo.correlati.length ? (
        <section className="articolo-detail__correlati">
          <WContainer>
            <h3 className="articolo-detail__correlati__title">Articoli correlati</h3>
            <div className="swiper--with-pagination">
              <Swiper
                spaceBetween={16}
                slidesPerView={1.5}
                watchOverflow
                pagination={{ clickable: true }}
                breakpoints={{
                  320: {
                    slidesPerView: 1.5,
                  },
                  576: {
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 3,
                  },
                }}
              >
                {articolo.correlati.map((a) => (
                  <SwiperSlide key={a.pk}>
                    <ArticoloMagazineCard articolo={a} articolo_padre={articolo} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </WContainer>
        </section>
      ) : null}
    </main>
  )
}

export default ArticoloTemplate
