import React from 'react'
import { Page, TrattamentiListing } from 'components/organisms'

const TrattamentiListingTemplate = ({ pagina, trattamenti = null, attributiFiltri = null }) => {
  return (
    <Page pagina={pagina}>
      <TrattamentiListing trattamenti={trattamenti} attributiFiltri={attributiFiltri} />
    </Page>
  )
}

export default TrattamentiListingTemplate
