import React from 'react'
import { MetaSeo, BoxQuote, WContainer, Button, JsonLd } from 'components/atoms'
import {
  BoxHero,
  LineaHomeCard,
  BoxHelp,
  BoxMarketing2,
  BoxMarketingProdotti,
  ArticoloMagazineCard,
  ProdottoCard,
  PreFooter,
} from 'components/molecules'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useEffect } from 'react'
import { trackingPromoClick, trackingPromoView } from 'utils/gtm'

SwiperCore.use([Pagination])

const HomepageTemplate = ({ pagina, serverData, clientData }) => {
  useEffect(() => {
    if (serverData.linee?.length > 0) trackingPromoView(serverData.linee)
  }, [])

  return (
    <main className={`page homepage`}>
      <MetaSeo seo={{ ...pagina.seo, image: serverData.hero?.image }} />
      <JsonLd type="homepage" />
      {serverData.hero && (
        <section className="homepage__section">
          <BoxHero
            title={serverData.hero.titolo}
            text={serverData.hero.testo}
            image={serverData.hero.image}
            isHome
          />
        </section>
      )}

      {serverData.linee?.length > 0 ? (
        <section className="homepage__section">
          <WContainer>
            <div className="home-linee">
              <div className="home-linee__slider swiper--with-pagination">
                <Swiper
                  // onSlideChangeTransitionEnd={(e) => { // solo da mobile
                  //   trackingPromoView(serverData.linee.slice(e.activeIndex, e.activeIndex + 1))
                  // }}
                  spaceBetween={16}
                  slidesPerView={4}
                  watchOverflow
                  pagination={{ clickable: true }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    388: {
                      // 272*1.25 + 16 margin + 16*2 paddingcontainer
                      slidesPerView: 1.25,
                    },
                    592: {
                      // 272*2 + 16 margin + 16*2 paddingcontainer
                      slidesPerView: 2,
                    },
                    880: {
                      // 272*3 + 16*2 margin + 16*2 paddingcontainer
                      slidesPerView: 3,
                    },
                    1168: {
                      // 272*4 + 16*3 margin + 16*2 paddingcontainer
                      slidesPerView: 4,
                    },
                  }}
                >
                  {serverData.linee.map((linea, index) => (
                    <SwiperSlide key={'linea-' + index}>
                      <LineaHomeCard
                        linea={linea}
                        onClick={() => trackingPromoClick(linea, index)}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </WContainer>
        </section>
      ) : null}

      <section className="homepage__section">
        <BoxHelp />
      </section>

      {clientData.prodotti?.length > 0 ? (
        <section className="homepage__section">
          <WContainer>
            <div className="home-prodotti">
              <p className="home-prodotti__title">I prodotti più venduti</p>
              <div className="home-prodotti__slider swiper--with-pagination">
                <Swiper
                  spaceBetween={16}
                  slidesPerView={3}
                  watchOverflow
                  pagination={{ clickable: true }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1.5,
                    },
                    368: {
                      // 168 * 2 + 16 margin + 16*2 padding container
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {clientData.prodotti.map((prodotto, index) => (
                    <SwiperSlide key={'prodotto-' + index}>
                      <ProdottoCard prodotto={prodotto} waPosition={index + 1} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </WContainer>
        </section>
      ) : null}

      {clientData.boxMarketingProdotti && (
        <section className="homepage__section">
          <BoxMarketingProdotti box={clientData.boxMarketingProdotti} />
        </section>
      )}

      {clientData.boxMarketing2 && (
        <section className="homepage__section">
          <BoxMarketing2 box={clientData.boxMarketing2} />
        </section>
      )}

      {clientData.boxQuote && (
        <section className="homepage__section">
          <BoxQuote box={clientData.boxQuote} />
        </section>
      )}

      {clientData.articoliMagazine?.length > 0 ? (
        <section className="homepage__section">
          <WContainer>
            <div className="home-news">
              <p className="home-news__title">News al servizio della bellezza</p>
              <div className="home-news__slider swiper--with-pagination">
                <Swiper
                  spaceBetween={16}
                  slidesPerView={1.5}
                  pagination={{ clickable: true }}
                  watchOverflow
                  breakpoints={{
                    320: {
                      slidesPerView: 1.5,
                    },
                    576: {
                      slidesPerView: 2,
                    },
                    991: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {clientData.articoliMagazine.map((articolo, index) => (
                    <SwiperSlide key={'news-' + index}>
                      <ArticoloMagazineCard articolo={articolo} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="home-news__cta-box">
                <Button
                  href="/magazine"
                  label="Scopri le altre storie"
                  className="home-news__cta"
                />
              </div>
            </div>
          </WContainer>
        </section>
      ) : null}
      <PreFooter />
    </main>
  )
}

export default HomepageTemplate
