import { WContainer } from 'components/atoms'
import { FormRegistrazioneComplete, Page } from 'components/organisms'

function RegistrazioneCompleteTemplate({ pagina }) {
  return (
    <Page pagina={pagina} classes="registrazione-complete">
      <WContainer inner>
        <FormRegistrazioneComplete />
      </WContainer>
    </Page>
  )
}

export default RegistrazioneCompleteTemplate
