// Utili per mockare i dati per storybook
export const mockArray = (mock, num) => {
  return [...Array(num).keys()].map((k) => mock(k + 1))
}

export const mockVideoTrattamenti = (index = 1) => {
  return {
    url: index % 2 == 1 ? '-qIAW6-o2Mw' : '129227787',
    tipoVideo: index % 2 == 1 ? 'Youtube' : 'Vimeo',
  }
}

export const mockTrattamenti = (index = 1) => {
  return {
    id: index ? index : 1,
    title: 'Soin Fusion Exfoliante',
    abstract: "Un nuovo modo di interpretare l'esfoliazione.",
    description:
      "Una fusione tra corpo e gommage, anche grazie all'utilizzo della spugna Konjac.\
    Dedicato a chi desidera un trattamento che, oltre all'azione esfoliante, preservi la pelle dall'invecchiamento.\
    Il risultato sarà una pelle liscia e levigata, dal colorito uniforme e dall'epidermide visibilmente più giovane.",
    image: 'https://via.placeholder.com/368x260',
    tipologiePelle: [
      {
        title: 'Sensibili',
        slug: 'sensibili',
      },
      {
        title: 'Normali',
        slug: 'normali',
      },
      {
        title: 'Mature',
        slug: 'mature',
      },
    ],
    durateTrattamento: [
      {
        title: '45 minuti',
        minutes: 45,
      },
    ],
    tipologieTrattamento: [
      {
        title: 'Antimacchie',
        slug: 'antimacchie',
      },
    ],
    zonaTrattamento: {
      title: 'Bifasico',
      slug: 'bifasico',
    },
    videoTrattamento: mockVideoTrattamenti(1),
  }
}

export const mockProdotti = (index = 1) => {
  return {
    id: index,
    pk: index,
    novita: true,
    prezzo: 45,
    prezzoScontato: 39,
    url: '#',
    linea: {
      colore: '#BA9BC4',
    },
    titolo: 'Authentik-beauty',
    sottotitolo: 'Crema idratante giorno',
    url: '/prodotto/slug-' + index,
  }
}

export const mockLinee = (index = 1) => {
  return {
    id: index,
    pk: index,
    image: 'https://via.placeholder.com/500',
    // 'https://hairandbeautyshop.it/46448-home_default/matis-reponse-delicate-sensi-demak-cream-200ml.jpg',
    imageHover:
      'https://images.glamour.it/wp-content/uploads/2018/01/1515972016_F96B7CB4-3E05-48F7-B682-AF7451D938E4-430x534.jpeg',
    nome: 'Linea ' + index,
    url: '/linee/edizione-limitata/',
  }
}

export const mockIndirizzi = (index = 1) => {
  return {
    id: index,
    pk: index,
    nome: 'Nome',
    cognome: 'Cognome',
    indirizzo: 'via Indirizzo ' + index,
    cap: '20100',
    citta: 'Milano',
    provincia: 'MI',
    nazione: 'Italia',
    note_aggiuntive: 'Note aggiuntive ' + index,
    cf: 'BCDFGH80A01A123Z',
  }
}

export const mockRigheOrdini = (index = 1) => {
  return {
    prodotto: mockProdotti(index),
    quantitaOrdinata: index,
    prezzoTotale: 200,
    prezzoTotaleScontato: 185,
  }
}

export const mockOrdini = (index = 1) => {
  return {
    id: index,
    pk: index,
    data: '2020-10-02',
    indirizzoSpedizione: mockIndirizzi(index),
    indirizzoFatturazione: mockIndirizzi(index + 10000),
    sistemaPagamento: {
      alias: 'Alias pagamento',
      nome: 'Nome pagamento',
    },
    sistemaSpedizione: {
      prezzoScontato: 0,
    },
    promozione: {
      alias: 'BLACKFRIDAY2020',
    },
    attivo: true,
    stato: {
      pk: 3,
      chiave: 'confermato',
    },
    righeOrdine: mockArray(mockRigheOrdini, 2),
  }
}

export const mockArticoli = (index = 1) => {
  return {
    id: index,
    pk: index,
    categoria: {
      title: 'Skincare routine',
    },
    title: 'La perfetta routine bodymotion ',
    abstract:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod sit amet elit, consectetur adipiscing elit, sed do eiusmod',
    publicationDate: '14/11/2020',
    url: '/magazine/slug-' + index,
  }
}

export const mockAttributiFiltri = (index = 1) => {
  return {
    id: index,
    nome: 'Attributo',
    valori_disponibili: [
      { id: 1, nome: 'Antimacchie' },
      { id: 2, nome: 'Detox' },
      { id: 3, nome: 'Esfoliante' },
      { id: 4, nome: 'Purificante' },
      { id: 5, nome: 'Lenitivo e calmante' },
      { id: 6, nome: 'Nutriente' },
      { id: 7, nome: 'Anti age' },
      { id: 8, nome: 'Illuminante' },
    ],
  }
}

export const breadcrumbsMock = [{ titolo: 'Home', url: '/' }, { titolo: 'Titolo' }]

export const seoMock = JSON.stringify({
  titolo: 'Title',
  description: 'Description',
  image: null,
})

export const pageMock = {
  seo: seoMock,
  breadcrumbs: breadcrumbsMock,
  titolo: 'Titolo',
  descrizione:
    'Descrizione Lorem ipsum dolor sit amet, consectetur adipiscing elit. ' +
    'Morbi condimentum elementum dolor sed auctor. Praesent consequat non arcu quis imperdiet. ' +
    'Quisque ullamcorper, eros non ornare sollicitudin, enim ligula fringilla leo, pulvinar sodales quam nulla in orci. ' +
    'Fusce augue arcu, rhoncus at magna non, rhoncus congue nunc. ',
}

export const trattamentoMock = mockTrattamenti(1)
export const attributoFiltroMock = mockAttributiFiltri(1)
export const videoTrattamentoMock = mockVideoTrattamenti(1)
export const prodottoMock = mockProdotti(1)
export const articoloMock = mockArticoli(1)
export const lineaMock = mockLinee(1)
export const ordineMock = mockOrdini(1)
