import { useQuery } from '@apollo/client'
import { CheckoutLayout, FormCheckout, IlTuoOrdine } from 'components/organisms'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { CHECKOUT } from 'utils/queries'

function CheckoutTemplate({ pagina }) {
  const { loading, data, error } = useQuery(CHECKOUT, { fetchPolicy: 'network-only' })

  const router = useRouter()

  useEffect(() => {
    handleRedirect(data?.checkout)
  }, [data?.checkout])

  function handleRedirect(checkout) {
    if (!loading && !checkout?.items.length) router.replace('/cart')
  }

  return (
    <CheckoutLayout
      pagina={pagina}
      data={data?.checkout}
      loading={loading || !data?.checkout?.items.length}
      main={<FormCheckout checkout={data?.checkout} classes="checkout__form" />}
      side={<IlTuoOrdine checkout={data?.checkout} classes="checkout__ordine" />}
    />
  )
}

export default CheckoutTemplate
