import { WContainer } from 'components/atoms'
import { FormSelezionaCentroCheckout, Page } from 'components/organisms'

const SelezionaCentroCheckoutTemplate = ({ pagina }) => {
  return (
    <Page pagina={pagina} classes="seleziona-centro">
      <WContainer inner>
        <FormSelezionaCentroCheckout />
      </WContainer>
    </Page>
  )
}

export default SelezionaCentroCheckoutTemplate
