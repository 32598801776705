import React, { useEffect, useState } from 'react'
import { MyAccountBlock, ProdottoCard } from 'components/molecules'
import { WISHLIST } from 'utils/queries'
import { useQuery } from '@apollo/client'
import { trackingProductImpression } from 'utils/gtm'

const MyAccountWishlistTemplate = ({}) => {
  const [items, setItems] = useState([])

  const { data: wishlistData } = useQuery(WISHLIST)

  useEffect(() => {
    if (wishlistData?.wishlist.items) {
      setItems(wishlistData.wishlist.items)
      trackingProductImpression(wishlistData.wishlist.items, 'wishlist')
    }
  }, [wishlistData])

  return (
    <div className="myaccount-wishlist">
      <MyAccountBlock title="La mia wishlist">
        {items.length ? (
          <div className="myaccount-wishlist__list">
            {items.map((item, index) => (
              <div key={item.prodotto.pk} className="myaccount-wishlist__item">
                <ProdottoCard prodotto={item.prodotto} waPosition={index + 1} />
              </div>
            ))}
          </div>
        ) : (
          <div className="myaccount-wishlist__empty">
            Non hai ancora aggiunto nessun prodotto alla wishlist.
          </div>
        )}
      </MyAccountBlock>
    </div>
  )
}

export default MyAccountWishlistTemplate
